import styled from "styled-components";
import * as Types from "./types";
export * from "./types";

export const WheelButton = styled.svg<Types.WheelButtonProps>`
  position: absolute;
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  user-select: none;
  pointer-events: none;
  max-height: 13px;
  transform: scale(1.2);
  opacity: 90%;
`;

export const WheelButtonContainer = styled.div<Types.WheelButtonProps>`
  position: absolute;
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  user-select: none;
  pointer-events: none;
  // max-height: 13px;
  width: 61px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-color: white;
  // border-width: 3px;
  // border-style: solid;
`;

export const TextIconContainer = styled.div<Types.WheelButtonProps>`
  position: absolute;
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  user-select: none;
  pointer-events: none;
`;

export const EmojiIcon = styled.h3<Types.EmojiIconProps>`
  font-size: ${(props) => props.fontSize};
  user-select: none;
  pointer-events: none;
  color: white;
  user-select: none;
  pointer-events: none;
  opacity: ${(props) => props.opacity ?? "100%"};
`;

export const TextIcon = styled.h3`
  font-size: 27px;
  user-select: none;
  pointer-events: none;
  color: white;
  user-select: none;
  pointer-events: none;
`;

export const ImageIcon = styled.img`
  // height: 90px;
  width: 100%;
  transform: scale(0.7);
`;
