import {
  WheelButtonProps,
  WheelButtonContainer,
  TextIconContainer,
  EmojiIcon,
} from "../Styled";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

interface Props extends WheelButtonProps {
  color?: string;
}

// const HelpIcon = (props: Props) => {
//   return (
//     <TextIconContainer {...props}>
//       <EmojiIcon opacity="60%" fontSize={"38px"}>
//         ❔
//       </EmojiIcon>
//     </TextIconContainer>
//   );
// };

const HelpIcon = (props: Props) => (
  <WheelButtonContainer {...props}>
    <QuestionMarkIcon
      sx={{
        fontSize: 35,
        color: "white",
        opacity: "90%",
        // borderColor: "white",
        // borderWidth: "3px",
        // borderStyle: "solid",
      }}
    />
  </WheelButtonContainer>
);

export default HelpIcon;
