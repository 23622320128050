import {
  WheelButton,
  WheelButtonProps,
  WheelButtonContainer,
  CircleIcon,
} from "../Styled";
import HomeIconComponent from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";

interface Props extends WheelButtonProps {
  color?: string;
  onClick: () => void;
}

const HomeIcon = (props: Props) => (
  <WheelButtonContainer {...props}>
    <HomeIconComponent
      sx={{
        fontSize: 27,
        color: "white",
        opacity: "90%",
      }}
    />
    <CircleIcon src={"circle2.png"} />
  </WheelButtonContainer>
);

export default HomeIcon;
