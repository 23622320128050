import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function isMobileBrowser(): boolean {
  // Check if the user agent string matches any known mobile user agent strings
  const mobileUserAgents = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  for (const mobileUserAgent of mobileUserAgents) {
    if (navigator.userAgent.toLowerCase().indexOf(mobileUserAgent) > -1) {
      return true;
    }
  }
  return false;
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let useShortHeightUI = !isMobileBrowser() && windowDimensions.height < 750;

  return { windowDimensions, useShortHeightUI };
}

// window.screen.orientation.lock("portrait");

// window.addEventListener("orientationchange", function () {
//   if (
//     window.screen.orientation.angle === 90 ||
//     window.screen.orientation.angle === -90
//   ) {
//     window.screen.orientation.lock("portrait");
//   }
// });
