import {
  WheelButtonProps,
  WheelButtonContainer,
  TextIconContainer,
  EmojiIcon,
  ImageIcon,
  CircleIcon,
} from "../Styled";

interface Props extends WheelButtonProps {
  color?: string;
  onClick: () => void;
}

const GenerateSongIcon = (props: Props) => (
  <WheelButtonContainer {...props}>
    <ImageIcon src={"gen-logo1.png"} />
    <CircleIcon src={"circle2.png"} />
  </WheelButtonContainer>
);

// const GenerateSongIcon = (props: Props) => {
//   return (
//     <TextIconContainer {...props}>
//       <EmojiIcon fontSize={"40px"}>✨</EmojiIcon>
//     </TextIconContainer>
//   );
// };

export default GenerateSongIcon;
