import { WheelButton, WheelButtonProps, WheelButtonContainer } from "../Styled";
import HomeIconComponent from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";

interface Props extends WheelButtonProps {
  color?: string;
}

const HomeIcon = (props: Props) => (
  <WheelButtonContainer {...props}>
    <HomeIconComponent
      sx={{
        fontSize: 35,
        color: "white",
        opacity: "90%",
      }}
    />
  </WheelButtonContainer>
);

export default HomeIcon;
