import { useScrollHandler, useSettings } from "hooks";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { Screen } from "utils/constants";

const TUTORIAL_WIDTH = "370px";

const Image = styled(motion.img)`
  height: auto;
  width: 100%;
  width: 370px;
  z-index: 100;
`;

const ImageContainer = styled(motion.div)`
  position: fixed;
  width: 370px;
  top: 223px;
  ${Screen.SM.MediaQuery} {
    top: 142px;
  }
  left: calc(50vw - 185px);
  z-index: 100;
`;

const DarkenBackground = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: black;
  opacity: 0.88;
  position: fixed;
`;

const Tutorial = () => {
  const { tutorialShowing, toggleTutorial } = useSettings();
  return (
    <AnimatePresence>
      {tutorialShowing ? (
        <motion.div onClick={() => toggleTutorial()}>
          <ImageContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
          >
            <Image
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              src="tutorial-overlay-2.png"
            />
          </ImageContainer>
          <DarkenBackground
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.88 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
          />
        </motion.div>
      ) : undefined}
    </AnimatePresence>
  );
};

export default Tutorial;
