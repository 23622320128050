import LoadingIndicator from "components/LoadingIndicator";
import { useAudioPlayer, useInterval } from "hooks";
import { useEffect } from "react";
import styled from "styled-components";
import * as Utils from "utils";
import { Unit } from "utils/constants";
import { analytics } from "firebase/clientApp";
import { logEvent } from "firebase/analytics";

import ProgressBar from "./ProgressBar";
import { createIpodEvent } from "utils/events";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 1em;
  padding: 0 ${Unit.SM};
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, transparent), to(rgba(250, 250, 250, 0.1)));
`;

interface LabelProps {
  textAlign: "left" | "right";
}

const Label = styled.h3<LabelProps>`
  // font-size: 13px;
  font-size: 12px;
  // margin: 1px 0;
  margin: auto 0;
  width: 30px;
  text-align: ${(props) => props.textAlign};
  color: white;
`;

const playPauseClickEvent = createIpodEvent("playpauseclick");
const TrackProgress = () => {
  const { playbackInfo, updatePlaybackInfo, nowPlayingItem } = useAudioPlayer();

  const {
    isLoading,
    isPlaying,
    isPaused,
    currentTime,
    percent,
    timeRemaining,
  } = playbackInfo;

  /** Update the progress bar every second. */
  useInterval(() => {
    if (isPlaying && !isPaused) {
      updatePlaybackInfo();
    }
  }, 1000);

  useEffect(() => {
    if (percent === 100) {
      window.dispatchEvent(playPauseClickEvent); // TODO: make this just pause;
    } else if (percent > 85 && percent < 99) {
      logEvent(analytics, "Stats.SongCompleted", {
        song_name: nowPlayingItem?.id,
      });
      return;
    } else if (currentTime > 30.0) {
      // Spotify counts a play after 30 seconds...
      logEvent(analytics, "Stats.SongPlayed", {
        song_name: nowPlayingItem?.id,
      });
      return;
    }
  }, [percent > 85, currentTime > 30.0, percent === 100]);

  return (
    <Container>
      {/* <LoadingIndicator size={14} /> */}
      {isLoading ? (
        <LoadingIndicator size={14} />
      ) : (
        <Label textAlign="left">{Utils.formatTime(currentTime)}</Label>
      )}
      <ProgressBar percent={percent} />
      {/* {timeRemaining ? ( */}
      {timeRemaining && (
        <Label textAlign="right">-{Utils.formatTime(timeRemaining)}</Label>
      )}
      {/* {timeRemaining ? (
        <Label textAlign="right">-{Utils.formatTime(timeRemaining)}</Label>
      ) : (
        <LoadingIndicator size={14} />
      ) } */}
    </Container>
  );
};

export default TrackProgress;
