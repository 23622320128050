const XXXL = '8rem'; // 128px
const XXL = '4rem'; // 64px
const XL = '2rem'; // 32px
const LG = '1.5rem'; // 24px
const MD = '1rem'; // 16px
const SM = '0.75rem'; // 12px
const XS = '0.5rem'; // 8px
const XXS = '0.25rem'; // 4px

const Unit = {
  XXXL,
  XXL,
  XL,
  LG,
  MD,
  SM,
  XS,
  XXS,
};

export default Unit;
