import { SelectableList, SelectableListOption } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import styled from "styled-components";
import { Unit } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: ${Unit.XL};
  width: auto;
  margin: ${Unit.XS};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Unit.MD} ${Unit.MD} 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
`;

const Description = styled.h3`
  margin: 0 0 ${Unit.MD};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`;

const ListContainer = styled.div`
  flex: 1;
`;

const ContactView = () => {
  useMenuHideWindow(ViewOptions.contact.id);
  const options: SelectableListOption[] = [
    {
      type: "Link",
      label: "Instagram",
      activeLabel: "Instagram @plx.555",
      url: "https://www.instagram.com/plx.555/",
    },
    // {
    //   type: "Link",
    //   label: "TikTok",
    //   activeLabel: "TikTok - @plx.world",
    //   url: "https://www.tiktok.com/@plx.world",
    // },
    {
      type: "Link",
      label: "Twitter",
      activeLabel: "Twitter @plx555",
      url: "https://twitter.com/plx555",
    },
    {
      type: "Link",
      label: "Email",
      activeLabel: "Email: plx9790@gmail.com",
      url: "mailto:plx9790@gmail.com",
    },
  ];

  const { scrollIndex, setActiveIndex } = useScrollHandler(
    ViewOptions.contact.id,
    options
  );

  return (
    <Container>
      <ListContainer>
        <SelectableList
          options={options}
          activeIndex={scrollIndex}
          setActiveIndex={setActiveIndex}
        />
      </ListContainer>
    </Container>
  );
};

export default ContactView;
