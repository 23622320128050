import { SelectableListOption } from "components";

const LOCAL_ACCESS_KEY = "plx23";

export const createNotPlayedVersionsArray = (
  totalVersions: string,
  currentVersion: string
): string[] => {
  let notPlayedArray = [];
  let totalVersionsInt = parseInt(totalVersions);
  for (let i = 1; i <= totalVersionsInt; i++) {
    if (i.toString() !== currentVersion) {
      notPlayedArray.push(i.toString());
    }
  }
  return notPlayedArray;
};

export const getGenerativeUrls = (
  generativeSong:
    | GenerativeApi.GenerativeSongServerData
    | {
        songUri: GenerativeApi.GenerativeUri;
        artworkUri: GenerativeApi.GenerativeUri;
        numberOfVersions: number;
      },
  versionsNotPlayed: string[]
  // currentVersionNumber?: number,
): GenerativeApi.GenerativeUrls => {
  // let newVersionNumber = Math.floor(
  //   Math.random() * generativeSong.numberOfVersions + 1
  // );

  // if (currentVersionNumber && currentVersionNumber === newVersionNumber) {
  //   while (currentVersionNumber === newVersionNumber) {
  //     newVersionNumber = Math.floor(
  //       Math.random() * generativeSong.numberOfVersions + 1
  //     );
  //   }
  // }

  let randomIndex = Math.floor(Math.random() * versionsNotPlayed.length);

  let newVersionNumber = versionsNotPlayed.splice(randomIndex, 1);

  let songUrl =
    generativeSong.songUri.versionsFolderUri +
    "/" +
    newVersionNumber +
    generativeSong.songUri.fileExtension;

  let artworkUrl =
    generativeSong.artworkUri.versionsFolderUri +
    "/" +
    newVersionNumber +
    generativeSong.artworkUri.fileExtension;

  return {
    songUrl,
    artworkUrl,
    currentVersionNumber: newVersionNumber.toString(),
    versionsNotPlayed,
  };
};

// export let songMetadataMap = new Map<string, number>();

// const loadedAudio = () => console.log(">>> LOADED Audio File");
// const loadedMetadata = (a: any) => {
//   console.log(">>> metadata: ", {
//     duration: a.target.duration,
//     t: a.target?.currentSrc,
//   });

//   if (a.target?.currentSrc) {
//     songMetadataMap.set(a.target.currentSrc, a.target.duration);
//   }
// };

export const preloadImagesAndSongs = (
  imageUrlList: string[],
  songsUrlList: string[]
) => {
  let fragment = document.createDocumentFragment();

  // Preload Images
  for (let i = 0; i < imageUrlList.length; i++) {
    const imgUrl = imageUrlList[i];
    const linkEl = document.createElement("link");
    linkEl.setAttribute("rel", "preload");
    linkEl.setAttribute("href", imgUrl);
    linkEl.setAttribute("as", "image");
    fragment.appendChild(linkEl);
  }

  // Preload Songs
  for (let i = 0; i < songsUrlList.length; i++) {
    const songUrl = songsUrlList[i];

    let a = new Audio(songUrl);
    // a.addEventListener("canplaythrough", loadedAudio, false);
    // a.addEventListener("loadedmetadata", loadedMetadata, false);

    // const linkEl = document.createElement("link");
    // // linkEl.setAttribute("rel", "prefetch");
    // linkEl.setAttribute("rel", "preload");
    // linkEl.setAttribute("href", songUrl);
    // linkEl.setAttribute("as", "audio");
    // fragment.appendChild(linkEl);
  }
  document.head.appendChild(fragment);
};

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function isLocalKey(accessKey: string): boolean {
  return accessKey === LOCAL_ACCESS_KEY;
}

export function accessKeyIsAuthorized(
  accessKey: string,
  keysString: string
  // keysArray?: string[]
): boolean {
  let keysArray = keysString.split(",");

  for (let i = 0; i < keysArray.length; i++) {
    let key = keysArray[i];
    if (key === accessKey) {
      return true;
    }
  }

  // Verify if key expired
  // try {
  //   let accessKeyDate = parseInt(accessKey);
  //   let currTime = Date.now();
  //   if (currTime < accessKeyDate) {
  //     return true;
  //   }
  // } catch (e) {}

  return false;
}

/** Accepts a url with '{w}' and '{h}' and replaces them with the specified size */
export const getArtwork = (size: number | string, url?: string) => {
  if (!url) {
    return undefined;
  }

  return url.replace("{w}", `${size}`).replace("{h}", `${size}`);
};

export const formatTime = (seconds = 0, guide = seconds) => {
  let s: number | string = Math.floor(seconds % 60);
  let m: number | string = Math.floor((seconds / 60) % 60);
  let h: number | string = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  if (isNaN(seconds) || seconds === Infinity) {
    h = m = s = "-";
  }

  h = h > 0 || gh > 0 ? `${h}:` : "";
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;
  s = s < 10 ? `0${s}` : s;

  return h + m + s;
};

export const setDocumentSongTitle = (song?: AppleMusicApi.Song) => {
  document.title = song
    ? `${song.attributes?.name ?? "Music"} – iPod.js`
    : "iPod.js";
};

/** Returns a list of playback options to display in a popup for an album, song, or playlist. */
export const getMediaOptions = (
  type: "album" | "song" | "playlist",
  id: string
): SelectableListOption[] => {
  return [];
  const music = window.MusicKit.getInstance();

  return [
    {
      type: "Action",
      label: "Play Next",
      onSelect: () =>
        music.playNext({
          [type]: id,
        }),
    },
    {
      type: "Action",
      label: "Play Later",
      onSelect: () =>
        music.playLater({
          [type]: id,
        }),
    },
  ];
};

export const isDev = () => window.location.origin.includes("localhost:3000");
