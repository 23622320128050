import { useCallback, useEffect, useMemo } from "react";

import { SelectableList, SelectableListOption } from "components";
import {
  GenerativeSongsView,
  NowPlayingView,
  AboutView,
  ViewOptions,
  WINDOW_TYPE,
} from "components/views";
import {
  useAudioPlayer,
  useEffectOnce,
  useEventListener,
  useMusicKit,
  useScrollHandler,
  useSettings,
  useSpotifySDK,
  useWindowContext,
} from "hooks";
import { IpodEvent } from "utils/events";
import ContactView from "../ContactView";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/clientApp";
import NextDropView from "../NextDropView";
import {
  createNotPlayedVersionsArray,
  getGenerativeUrls,
  preloadImagesAndSongs,
} from "utils";
import { analytics } from "firebase/clientApp";
import { logEvent } from "firebase/analytics";

const strings = {
  nowPlaying: "Now Playing",
};

const HomeView = () => {
  const { isAuthorized } = useSettings();
  const { signIn: signInWithApple, isConfigured: isMkConfigured } =
    useMusicKit();
  const { nowPlayingItem, setInitialSongPlaceholder } = useAudioPlayer();
  const { signIn: signInWithSpotify } = useSpotifySDK();
  const { showWindow, windowStack } = useWindowContext();

  const generativeSongsCollection = collection(firestore, "generativeSongs");
  const [generativeSongs, generativeSongsLoading, generativeSongsError] =
    useCollection(generativeSongsCollection);

  let allArtworkUrls: string[] = [];
  let allCurrentSongUrls: string[] = [];
  let songs: IpodApi.Song[] = useMemo(
    () =>
      generativeSongs?.docs
        .filter((s) => !s.data().isHidden)
        .map((generativeSongRaw) => {
          try {
            const generativeSong =
              generativeSongRaw.data() as GenerativeApi.GenerativeSongServerData;

            let initVersionNumber = Math.floor(
              Math.random() * generativeSong.numberOfVersions + 1
            );

            const versionsNotPlayed = createNotPlayedVersionsArray(
              generativeSong.numberOfVersions.toString(),
              initVersionNumber.toString()
            );

            let generativeUrls: GenerativeApi.GenerativeUrls =
              getGenerativeUrls(generativeSong, versionsNotPlayed);

            allArtworkUrls.push(generativeUrls.artworkUrl);
            allCurrentSongUrls.push(generativeUrls.songUrl);

            return {
              name: generativeSong.name,
              duration: 0,
              id: generativeSong.name,
              trackNumber: generativeSong.trackNumber,
              url: generativeUrls.songUrl,
              artwork: {
                width: 100,
                height: 100,
                url: generativeUrls.artworkUrl,
              },
              albumName: generativeSong.album,
              artistName: generativeSong.artist,
              currentVersionNumber: generativeUrls.currentVersionNumber,
              totalVersions: generativeSong.numberOfVersions.toString(),
              songUris: generativeSong.songUri,
              artworkUris: generativeSong.artworkUri,
              versionsNotPlayed,
            };
          } catch (e: any) {
            const stringifiedError = e.toString();
            logEvent(analytics, "Error.Parsing.SongsMetadata", {
              error_message: stringifiedError,
            });
            return {
              duration: 100,
              id: "fallback",
              name: "please refresh your browser",
              trackNumber: 99,
              url: "https://supernova.mypinata.cloud/ipfs/Qmei8DW9U1DjfDJTqSvfvSHxoXKAGzhYw74HuRFTQpirQp/17.mp3",
            };
          }
        }) ?? [],
    [generativeSongs]
  );

  useEffect(() => {
    if (!generativeSongsLoading) {
      preloadImagesAndSongs(allArtworkUrls, allCurrentSongUrls);
    }
  }, [generativeSongsLoading]);

  useEffect(() => {
    if (songs.length > 0 && !nowPlayingItem) {
      setInitialSongPlaceholder(songs[0]);
    }
  }, [JSON.stringify(songs)]);

  /**
   * Updates song list on Regen
   */
  useEffect(() => {
    for (let i = 0; i < songs.length; i++) {
      const song = songs[i];
      if (song.id === nowPlayingItem?.id) {
        let updatedSong = {
          ...song,
          artwork: nowPlayingItem.artwork,
          url: nowPlayingItem.url,
          currentVersionNumber: nowPlayingItem.currentVersionNumber,
        };

        songs[i] = updatedSong;
      }
    }
  }, [nowPlayingItem?.currentVersionNumber]);

  const options: SelectableListOption[] = useMemo(() => {
    return [
      {
        type: "View",
        label: "Generative Songs",
        viewId: ViewOptions.generativeSongs.id,
        component: () => <GenerativeSongsView songs={songs!} />,
      },
      {
        type: "View",
        label: "About",
        viewId: ViewOptions.about.id,
        component: () => <AboutView />,
      },
      {
        type: "View",
        label: "Contact",
        viewId: ViewOptions.contact.id,
        component: () => <ContactView />,
      },
      // {
      //   type: "View",
      //   label: "Next Drop",
      //   viewId: ViewOptions.nextDrop.id,
      //   component: () => <NextDropView />,
      // },
      // {
      //   type: "View",
      //   label: "Static Song Versions",
      //   viewId: ViewOptions.staticSongVersions.id,
      //   component: () => <StaticSongVersionsView />,
      // },
    ];
  }, [
    isAuthorized,
    isMkConfigured,
    nowPlayingItem,
    signInWithApple,
    signInWithSpotify,
    JSON.stringify(songs),
    nowPlayingItem?.currentVersionNumber,
  ]);

  const { scrollIndex, setActiveIndex } = useScrollHandler(
    ViewOptions.home.id,
    options
  );

  const handleIdleState = useCallback(() => {
    const activeView = windowStack[windowStack.length - 1];

    const shouldShowNowPlaying = false;
    // !!nowPlayingItem &&
    // activeView.id !== ViewOptions.nowPlaying.id &&
    // activeView.id !== ViewOptions.coverFlow.id &&
    // activeView.id !== ViewOptions.keyboard.id;

    // Only show the now playing view if we're playing a song and not already on that view.
    if (shouldShowNowPlaying) {
      showWindow({
        id: ViewOptions.nowPlaying.id,
        type: WINDOW_TYPE.FULL,
        component: NowPlayingView,
      });
    }
  }, [nowPlayingItem, showWindow, windowStack]);

  useEventListener<IpodEvent>("idle", handleIdleState);

  return (
    <SelectableList
      options={options}
      activeIndex={scrollIndex}
      setActiveIndex={setActiveIndex}
    />
  );
};

export default HomeView;
