import { useCallback, useRef, useState } from "react";

import {
  useEffectOnce,
  useEventListener,
  useSettings,
  useWindowContext,
} from "hooks";

import Knob from "./ButtonsBarContainer";
import { createIpodEvent } from "utils/events";
import { analytics } from "firebase/clientApp";
import { logEvent } from "firebase/analytics";
import ButtonsBarContainer from "./ButtonsBarContainer";

enum WHEEL_QUADRANT {
  TOP = 1,
  BOTTOM = 2,
  LEFT = 3,
  RIGHT = 4,
}

type SupportedKeyCode =
  | "ArrowUp"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "Escape"
  | "Enter"
  | " "
  | "Spacebar";

const centerClickEvent = createIpodEvent("centerclick");
const centerLongClickEvent = createIpodEvent("centerlongclick");
const forwardScrollEvent = createIpodEvent("forwardscroll");
const backwardScrollEvent = createIpodEvent("backwardscroll");
const wheelClickEvent = createIpodEvent("wheelclick");
const menuClickEvent = createIpodEvent("menuclick");
const menuLongPressEvent = createIpodEvent("menulongpress");
const backClickEvent = createIpodEvent("backwardclick");
const forwardClickEvent = createIpodEvent("forwardclick");
const playPauseClickEvent = createIpodEvent("playpauseclick");
const idleEvent = createIpodEvent("idle");

const ButtonsBar = () => {
  const { deviceTheme } = useSettings();
  const [count, setCount] = useState(0);
  const timeoutIdRef = useRef<any>();

  const handleCenterClick = useCallback(() => {
    window.dispatchEvent(centerClickEvent);
  }, []);

  const handleCenterLongPress = useCallback((e: any) => {
    e.preventDefault();
    window.dispatchEvent(centerLongClickEvent);
  }, []);

  const handleMenuLongPress = useCallback((e: any) => {
    e.preventDefault();
    window.dispatchEvent(menuLongPressEvent);
  }, []);

  const handleClockwiseScroll = useCallback(
    () => window.dispatchEvent(forwardScrollEvent),
    []
  );

  const handleCounterClockwiseScroll = useCallback(() => {
    window.dispatchEvent(backwardScrollEvent);
  }, []);

  const handleResetIdleCheck = useCallback(() => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      window.dispatchEvent(idleEvent);
    }, 10000);
  }, []);

  const handleWheelClick = useCallback(
    (quadrant: number) => {
      window.dispatchEvent(wheelClickEvent);

      switch (quadrant) {
        case WHEEL_QUADRANT.TOP:
          logEvent(analytics, "UI.button.Back");
          window.dispatchEvent(menuClickEvent);
          break;
        case WHEEL_QUADRANT.BOTTOM:
          window.dispatchEvent(playPauseClickEvent);
          logEvent(analytics, "UI.button.PlayPause");
          break;
        case WHEEL_QUADRANT.LEFT:
          window.dispatchEvent(backClickEvent);
          logEvent(analytics, "UI.button.Tutorial");
          break;
        case WHEEL_QUADRANT.RIGHT:
          window.dispatchEvent(forwardClickEvent);
          logEvent(analytics, "UI.button.RegenerateTrack");
          break;
      }

      handleResetIdleCheck();
    },
    [handleResetIdleCheck]
  );

  /** Allows for keyboard navigation. */
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.key as SupportedKeyCode) {
        case "ArrowUp":
        case "ArrowLeft":
          handleCounterClockwiseScroll();
          break;
        case "ArrowDown":
        case "ArrowRight":
          handleClockwiseScroll();
          break;
        case "Enter":
          handleCenterClick();
          break;
        case " ":
        case "Spacebar":
          handleWheelClick(WHEEL_QUADRANT.BOTTOM);
          break;
        case "Escape":
          handleWheelClick(WHEEL_QUADRANT.TOP);
          break;
      }

      handleResetIdleCheck();
    },
    [
      handleResetIdleCheck,
      handleCounterClockwiseScroll,
      handleClockwiseScroll,
      handleCenterClick,
      handleWheelClick,
    ]
  );

  useEventListener("keydown", handleKeyPress);

  /**
   * Start the countdown for detecting when the user is idle,
   * in which case we'll show the Now Playing view if music is playing
   */
  useEffectOnce(handleResetIdleCheck);

  return <ButtonsBarContainer onWheelClick={handleWheelClick} />;
};

export default ButtonsBar;
