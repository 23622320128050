import {
  CircleIcon,
  WheelButton,
  WheelButtonContainer,
  WheelButtonProps,
} from "../Styled";

interface Props extends WheelButtonProps {
  color?: string;
  onClick: () => void;
}

const PlayPauseIcon = (props: Props) => (
  <WheelButtonContainer {...props}>
    <WheelButton
      // scale={0.95}
      width="39"
      height="18"
      // width="100%"
      // height="100%"
      viewBox="0 0 39 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 9L4.5 16.7942L4.5 1.20577L18 9Z" fill={props.color} />
      <rect x="26" y="1" width="4" height="16" fill={props.color} />
      <rect x="35" y="1" width="4" height="16" fill={props.color} />
    </WheelButton>
    <CircleIcon src={"circle2.png"} />
  </WheelButtonContainer>
);

export default PlayPauseIcon;
