import { SelectableList, SelectableListOption } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import styled from "styled-components";
import { Unit } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: ${Unit.XL};
  width: auto;
  margin: ${Unit.XS};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Unit.MD} ${Unit.MD} 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
`;

const Description = styled.h3`
  padding-right: 20px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: gray;
  font-family: BlinkMacSystemFont;
`;

const ListContainer = styled.div`
  flex: 1;
`;

const StaticSongVersionsView = () => {
  useMenuHideWindow(ViewOptions.staticSongVersions.id);
  const options: SelectableListOption[] = [
    {
      type: "Link",
      label: "Spotify",
      url: "https://github.com/tvillarete/ipod-classic-js",
    },
    {
      type: "Link",
      label: "Apple Music",
      url: "http://tannerv.com",
    },
    {
      type: "Link",
      label: "SoundCloud",
      url: "https://linkedin.com/in/tvillarete",
    },
    {
      type: "Link",
      label: "Audius",
      url: "https://linkedin.com/in/tvillarete",
    },
  ];

  const { scrollIndex } = useScrollHandler(
    ViewOptions.staticSongVersions.id,
    options
  );

  return (
    <Container>
      <ListContainer>
        {/* <TitleContainer> */}
        {/* <Image alt="iPod" src="ipod_logo.svg" /> */}
        {/* <Title>iPod.js</Title> */}
        {/* </TitleContainer> */}
        {/* <Description>
          Listen to static versions of PLX songs using links below. PLX chooses
          a random Edition of the song to upload to the below music providers.
          To get the full generative experience, listen to them on this player
          instead.
        </Description> */}
        <SelectableList options={options} activeIndex={scrollIndex} />
      </ListContainer>
    </Container>
  );
};

export default StaticSongVersionsView;
