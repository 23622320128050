// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "plx5-36818.firebaseapp.com",
  projectId: "plx5-36818",
  storageBucket: "plx5-36818.appspot.com",
  messagingSenderId: "1095446294466",
  appId: "1:1095446294466:web:3e9ec42a3b8dc29fd1305b",
  measurementId: "G-CBEPG59RK8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
export const analytics = getAnalytics(app);
if (window.location.href.includes("localhost")) {
  console.log("LOCAL DEV!");
  setAnalyticsCollectionEnabled(analytics, false);
} else {
  setAnalyticsCollectionEnabled(analytics, true);
}

// Initialize Firestore
export const firestore = getFirestore(app);

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
