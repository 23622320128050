import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import styled from "styled-components";
import { Screen, Unit } from "utils/constants";

export const WheelButtonContainer = styled.div`
  position: relative;
  user-select: none;
  pointer-events: none;
  // max-height: 13px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-color: white;
  // border-width: 3px;
  // border-style: solid;
`;

export const ImageIcon = styled.img`
  padding-top: 10px;
  padding-bottom: 20px;
  height: 205px;
  width: auto;
`;
/*
<{ scrollStep: number }>
  transform: ${({ scrollStep }) =>
    scrollStep >= 26
      ? "translateY(-79%)"
      : `translateY(-${scrollStep * 3 + "%"})`}; */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.5s;
  transition-timing-function: linear;
`;

const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  border-width: 7px;
  border-color: #000000;
  // display: flex,
  // justifyContent: center,
  // alignItems: center,
`;

const Image = styled.img`
  // height: 50px; //${Unit.XL};
  // width: 50px; //auto;
  height: 50%;
  width: 50%;
  // border-image-width: 10px;
  // padding-left: 15px;
  // text-align: center;
  // margin: ${Unit.XS};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Unit.MD} ${Unit.MD} 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  color: white;
`;

const Description = styled.h3`
  margin: 0 0 ${Unit.MD};
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  color: #c7c7c7;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;

  ${Screen.SM.MediaQuery} {
    font-size: 18px;
    color: #edebeb;
  }
`;

const DescriptionTop = styled.h3`
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  color: #c7c7c7;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  ${Screen.SM.MediaQuery} {
    font-size: 18px;
    color: #edebeb;
  }
`;

const AboutView = () => {
  useMenuHideWindow(ViewOptions.about.id);
  // const { scrollIndex } = useScrollHandler(ViewOptions.about.id);

  return (
    // <Container ref={containerRef}>
    // scrollStep={scrollIndex}
    <Container>
      {/* <ListContainer> */}
      {/* <TitleContainer>
          <Image alt="iPod" src="ipod_logo.svg" />
          <Title>PLX</Title>
        </TitleContainer> */}
      <DescriptionTop>
        A great storyteller never tells a story twice
      </DescriptionTop>
      {/* <DescriptionTop>
        A great storyteller never tells the same story
      </DescriptionTop> */}
      <Description>
        PLX is a generative artist passionate about the evolution of music
      </Description>
      <Description>
        PLX is creating a new format of songs where every time they are played,
        a new, slightly modified version of it is rendered...
      </Description>
      <Description>
        This allows one to experience authentic variation of an artform, while
        keeping key elements, finding a middle between familiarity and novelty
        every time...
      </Description>
      <Description>
        The generative songs can be listened to on this player. Static versions
        of the songs are available on audio platforms like Spotify and Apple
        Music. Each Generative Song is auctioned as an NFT on Drop Day.
      </Description>
      <Description>
        PLX pursues the ”infinite works of nature” woven together in a unity
        filled with marvelous patterns - within music, philosophy, art,
        mathematics, design and technology.
      </Description>
      <Description>”Reality is sustained imagination”</Description>
      {/* <Description>✨</Description> */}
      <WheelButtonContainer>
        {/* <ImageIcon src={"plx-symbol3.png"} /> */}
        <ImageIcon src={"flower3.png"} />
      </WheelButtonContainer>
      {/* <Description></Description> */}
      {/* <ImageContainer /> */}
      {/* <Image alt="plxLogo" src="plxSymbol.png" /> */}
      {/* </ImageContainer> */}
      {/* <SelectableList options={options} activeIndex={scrollIndex} /> */}
      {/* </ListContainer> */}
      {/* <SelectableListItem
        key={`option-${options[options.length - 1]}-${options.length - 1}`}
        option={options[options.length - 1]}
        isActive={options.length - 1 === scrollIndex}
      /> */}
    </Container>
  );
};

export default AboutView;
