import * as React from "react";
import * as dotenv from "dotenv";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";

import { ScrollWheel, ButtonsBar } from "components";
import {
  AudioPlayerProvider,
  MusicKitProvider,
  SettingsProvider,
  SpotifySDKProvider,
  useSettings,
  useWindowDimensions,
} from "hooks";
import WindowProvider from "providers/WindowProvider";
import styled, { createGlobalStyle } from "styled-components";
import { Screen, Unit } from "utils/constants";

import { WindowManager } from "./components";
import { DeviceThemeName, getTheme } from "./utils/themes";
import { getParameterByName, accessKeyIsAuthorized, isLocalKey } from "./utils";

import { useCollection } from "react-firebase-hooks/firestore";
import { collection } from "firebase/firestore";
import { firestore } from "firebase/clientApp";
import { createIpodEvent } from "utils/events";

import { analytics } from "firebase/clientApp";
import { logEvent } from "firebase/analytics";

interface Flags {
  passwordEnabled: boolean;
  auctionEnabled: boolean;
}

const AppHeaderContainer = styled.div`
  color: white; // linear-gradient(180deg, #7d7c7d 0%, #1e1e1e 100%);
  font-family: Ethnocentric;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 15px;
  // padding-top: 35px;
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 40px;
  // height: 65px;
  font-size: 85px;
  // -webkit-text-stroke: 2px yellow; /* width and color */
  -webkit-text-stroke: 2px black; /* width and color */

  ${Screen.SM.MediaQuery} {
    width: 100vw;
    max-height: unset;
    // border-width: 3px;
    // border-color: white;
    // border-style: solid;
    // padding: 10px;
    padding-top: 5px;
    font-size: 75px;
    padding-bottom: 0px;
    border-radius: 0;
    -webkit-box-reflect: unset;
    // border-color: white;
    // border-width: 2px;
    // border-style: solid;
  }
`;

const PlxLogo = styled.img`
  // height: 90px;
  height: 105px;
  width: auto;
  ${Screen.SM.MediaQuery} {
    height: 90px;
  }
`;

dotenv.config();

export function AppHeader() {
  // return (
  //   <AppHeaderContainer>
  //     PLX
  //   </AppHeaderContainer>
  // );
  return (
    <AppHeaderContainer>
      <PlxLogo src={"PLX_Logo.png"} />
    </AppHeaderContainer>
  );
}

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Chiq_Pro_Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-size: 16px;
    user-select: none;
    -webkit-touch-callout: none;

    @media (prefers-color-scheme: dark) {
      background: black;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Shell = styled.div<{ deviceTheme: DeviceThemeName }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 375px;
  max-height: 37em;
  margin: auto;
  border-radius: 30px;
  box-shadow: inset 0 0 2.4em #555;
  // background: ${({ deviceTheme }) => getTheme(deviceTheme).body.background};
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(50%, transparent), to(rgba(250, 250, 250, 0.3)));
  animation: descend 1.5s ease;
  // overflow: hidden;

  @media (prefers-color-scheme: dark) {
    box-shadow: inset 0 0 2.4em black;
  }

  ${Screen.SM.MediaQuery} {
    animation: none;
    width: 100vw;
    max-height: auto;
    border-radius: 0;
    -webkit-box-reflect: unset;
  }

  @keyframes descend {
    0% {
      transform: scale(0.3);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Sticker = styled.div<{ deviceTheme: DeviceThemeName }>`
  position: absolute;
  height: 300px;
  background: ${({ deviceTheme }) =>
    getTheme(deviceTheme).body.sticker1?.background};
  ${({ deviceTheme }) => getTheme(deviceTheme).body.sticker1?.styles ?? {}};
`;

const Sticker2 = styled.div<{ deviceTheme: DeviceThemeName }>`
  position: absolute;
  background: ${({ deviceTheme }) =>
    getTheme(deviceTheme).body.sticker2?.background};
  ${({ deviceTheme }) => getTheme(deviceTheme).body.sticker2?.styles ?? {}};
`;

const Sticker3 = styled.div<{ deviceTheme: DeviceThemeName }>`
  position: absolute;
  background: ${({ deviceTheme }) =>
    getTheme(deviceTheme).body.sticker3?.background};
  ${({ deviceTheme }) => getTheme(deviceTheme).body.sticker3?.styles ?? {}};
`;

const ScreenContainer = styled.div`
  position: relative;
  height: 260px;
  margin: 20px ${Unit.MD} 0;
  border: 2px solid #404040; // here
  border-radius: ${Unit.XS};
  overflow: hidden;
  background: white;
  animation: fadeFromBlack 0.5s;
  // box-shadow: 0px -25px 80px -20px #353535;
  z-index: 4;

  @keyframes fadeFromBlack {
    0% {
      filter: brightness(0);
    }
  }

  ${Screen.SM.MediaQuery} {
    margin: 6px ${Unit.MD} 0;
    // margin: 0 0 0;
  }
`;

// Create a client
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <GlobalStyles />
        <SettingsProvider>
          <Ipod />
        </SettingsProvider>
      </Container>
    </QueryClientProvider>
  );
};

const centerClickEvent = createIpodEvent("centerclick");

const ControlButtons = () => {
  const { useShortHeightUI } = useWindowDimensions();
  return <> {useShortHeightUI ? <ButtonsBar /> : <ScrollWheel />}</>;
};

const Ipod = () => {
  const { deviceTheme, toggleTutorial } = useSettings();

  // const flagsCollection = collection(firestore, "flags");
  // const [flagsRaw, flagsLoading] = useCollection(flagsCollection);
  // let flags: any = flagsRaw?.docs ? flagsRaw?.docs[0].data() : {}; // TODO: proper casting

  /**
   * Verify user is authorized
   */
  let accessKey = getParameterByName("accessKey") ?? "";
  let isAuthorized = isLocalKey(accessKey);

  let authorizedKeys = process.env.REACT_APP_WEBSITE_ACCESS_KEYS ?? "";
  // if (!flagsLoading) {
  isAuthorized = process.env.REACT_APP_IS_PASSWORD_ENABLED
    ? accessKeyIsAuthorized(accessKey, authorizedKeys) || isAuthorized
    : true;
  // }

  console.log("v1.1.7");

  if (!isAuthorized) {
    logEvent(analytics, "Error.User.UnauthorizedKey", {
      key_provided: accessKey,
    });
  }

  return (
    <div className="fill-window">
      <AppHeader />
      {!isAuthorized ? (
        <div
          style={{
            color: "white",
            borderWidth: 5,
            borderColor: "white",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Request password
        </div>
      ) : (
        <Shell deviceTheme={deviceTheme}>
          <Sticker deviceTheme={deviceTheme} />
          <Sticker2 deviceTheme={deviceTheme} />
          <Sticker3 deviceTheme={deviceTheme} />
          <ScreenContainer
            onClick={() => {
              const isDesktopSite = true;
              if (isDesktopSite) {
                window.dispatchEvent(centerClickEvent);
              } else {
                toggleTutorial();
              }
            }}
          >
            <WindowProvider>
              <SpotifySDKProvider>
                <MusicKitProvider>
                  <AudioPlayerProvider>
                    <WindowManager />
                  </AudioPlayerProvider>
                </MusicKitProvider>
              </SpotifySDKProvider>
            </WindowProvider>
          </ScreenContainer>
          <ControlButtons />
        </Shell>
      )}
    </div>
  );
};

export default App;
