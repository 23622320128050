import styled from "styled-components";
import * as Types from "./types";
export * from "./types";

export const WheelButton = styled.svg<Types.WheelButtonProps>`
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  max-height: 13px;
  transform: scale(1.2);
  opacity: 90%;
  transform: ${(props) =>
    props.scale ? "scale(" + props.scale + ")" : "scale(1)"};
`;

export const WheelButtonContainer = styled.div<Types.WheelButtonProps>`
  position: relative;
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  width: 61px;
  height: 100%;
  transform: ${(props) =>
    props.scale ? "scale(" + props.scale + ")" : "scale(1)"};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: #a6a6a6; // #ffffff;
    box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
    transform: ${(props) => {
      const magnifier = 1; // 1.05;
      let newScale = props.scale ? props.scale * magnifier : magnifier;

      return "scale(" + newScale + ")";
    }};
    -webkit-box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
    -moz-box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
  }

  :active {
    background: #d1d1d1;
    box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
    -webkit-box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
    -moz-box-shadow: 0px 0px 21px 21px rgba(0, 0, 0, 1) inset;
    transform: ${(props) => {
      const magnifier = 0.92; // 1.08;
      let newScale = props.scale ? props.scale * magnifier : magnifier;

      return "scale(" + newScale + ")";
    }};
  }
`;

export const TextIconContainer = styled.div<Types.WheelButtonProps>`
  position: absolute;
  margin: ${(props) => props.margin};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
`;

export const EmojiIcon = styled.h3<Types.EmojiIconProps>`
  font-size: ${(props) => props.fontSize};
  user-select: none;
  color: white;
  opacity: ${(props) => props.opacity ?? "100%"};
`;

export const TextIcon = styled.h3`
  font-size: 27px;
  color: #ffffff;
`;

export const ImageIcon = styled.img`
  padding-left: 6px;
  height: 60px;
  transform: scale(0.65);
`;

export const CircleIcon = styled.img`
  position: fixed;
  height: 60px;
  opacity: 30%;
  transform: scale(1.3);
`;
