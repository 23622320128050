import { SelectableList, SelectableListOption } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import styled from "styled-components";
import { Screen, Unit } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: ${Unit.XL};
  width: auto;
  margin: ${Unit.XS};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Unit.MD} ${Unit.MD} 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
`;

const Description = styled.h3`
  // margin: 0 0 ${Unit.MD};
  // margin: 20 20 ${Unit.MD};
  padding-right: 20px;
  padding-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  color: #c7c7c7;
  ${Screen.SM.MediaQuery} {
    font-size: 18px;
    color: #edebeb;
  }
`;

const ListContainer = styled.div`
  flex: 1;
`;

const NextDropView = () => {
  useMenuHideWindow(ViewOptions.nextDrop.id);
  const options: SelectableListOption[] = [
    {
      type: "Link",
      label: "Sign up for updates",
      url: "https://github.com/tvillarete/ipod-classic-js",
    },
  ];

  const { scrollIndex } = useScrollHandler(ViewOptions.nextDrop.id, options);

  return (
    <Container>
      <ListContainer>
        {/* <TitleContainer> */}
        {/* <Image alt="iPod" src="ipod_logo.svg" /> */}
        {/* <Title>iPod.js</Title> */}
        {/* </TitleContainer> */}
        <Description>
          Next Generative Track drops Thursday, November 11th at 5pm EST
        </Description>
        <Description>Auction will be in ETH</Description>
        {/* NFT holders
        have direct access to PLX's private Telegram group and accompany this
        journey from up close. */}
        {/* <Description>1 Generative Track, 100 Versions Available</Description> */}
        {/* <SelectableList options={options} activeIndex={scrollIndex} /> */}
      </ListContainer>
    </Container>
  );
};

export default NextDropView;
