import styled, { css } from "styled-components";
import { Screen, Unit } from "utils/constants";

import { SelectableListOption } from "./";

const LabelContainer = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${Unit.MD};
  color: #fdfdfd;
  ${Screen.SM.MediaQuery} {
    color: white;
  }
`;

const Label = styled.h3<{ isActive?: boolean; hasImage?: boolean }>`
  margin: 0;
  // padding: ${Unit.XXS}; // 10px
  padding: ${({ hasImage }) => (!hasImage ? "5px" : "4px")};
  padding-left: ${({ hasImage }) => (!hasImage ? "10px" : Unit.XXS)};
  // font-size: 14px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Sublabel = styled(Label)`
  padding: 0 ${Unit.XXS} ${Unit.XXS};
  margin-top: -4px;
  font-weight: normal;
  font-size: 16px;
  color: rgb(100, 100, 100);
`;

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  overflow: auto;
  // :hover {
  //   background: #ffffff;
  // box-shadow: inset 0 0 10px #(color of the glow you want);
  // }

  ${(props) =>
    props.isActive &&
    css`
      ${LabelContainer} {
        padding-right: 0;
      }

      ${Label}, ${Sublabel} {
        color: ${!props.isActive ? "white" : "black"};
      }
      background: linear-gradient(rgb(60, 184, 255) 0%, rgb(52, 122, 181) 100%);
    `};
`;

const Image = styled.img`
  height: 3.4rem;
  width: 3.4rem;
  margin-right: ${Unit.XXS};
`;

const Icon = styled.img`
  margin-left: auto;
`;

interface Props {
  option: SelectableListOption;
  isActive: boolean;
  setItemActive: () => void;
}

const SelectableListItem = ({ option, isActive, setItemActive }: Props) => {
  return (
    <Container isActive={isActive} onMouseEnter={() => setItemActive()}>
      {option.imageUrl && <Image src={option.imageUrl} />}
      <LabelContainer>
        {isActive && !!option.activeLabel ? (
          <Label isActive={isActive} hasImage={!!option.imageUrl}>
            {option.activeLabel}
          </Label>
        ) : (
          <Label isActive={isActive} hasImage={!!option.imageUrl}>
            {option.label}
          </Label>
        )}
        {option.sublabel && <Sublabel>{option.sublabel}</Sublabel>}
      </LabelContainer>
      {isActive && <Icon src="arrow_right.svg" />}
    </Container>
  );
};

export default SelectableListItem;
