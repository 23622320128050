import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import { ViewOptions, WINDOW_TYPE, NowPlayingView } from "components";
import { useEventListener, useWindowContext } from "hooks";
import * as ConversionUtils from "utils/conversion";
import { IpodEvent } from "utils/events";
import { createNotPlayedVersionsArray, getGenerativeUrls } from "utils";
import { analytics } from "firebase/clientApp";
import { logEvent } from "firebase/analytics";

import { useMusicKit, useSettings, useSpotifySDK, VOLUME_KEY } from "../";

const defaultPlatbackInfoState = {
  isPlaying: false,
  isPaused: false,
  isLoading: true,
  currentTime: 0,
  timeRemaining: 0,
  percent: 0,
  duration: 0,
};

interface AudioPlayerState {
  playbackInfo: typeof defaultPlatbackInfoState;
  nowPlayingItem?: IpodApi.MediaItem;
  volume: number;
  play: (queueOptions: IpodApi.QueueOptions) => Promise<void>;
  pause: () => Promise<void>;
  seekToTime: (time: number) => void;
  setVolume: (volume: number) => void;
  updateNowPlayingItem: (song?: IpodApi.Song) => void;
  updatePlaybackInfo: () => void;
  resetPlaybackInfo: () => void;
  setInitialSongPlaceholder: (initialSong: IpodApi.Song) => void;
  setShouldAutoPlay: (hasPlayed: boolean) => void;
  setFirstSongLoadStartTime: (startTime: number) => void;
}

export const AudioPlayerContext = createContext<AudioPlayerState>({} as any);

type AudioPlayerHook = AudioPlayerState;

export const useAudioPlayer = (): AudioPlayerHook => {
  const state = useContext(AudioPlayerContext);

  return {
    ...state,
  };
};

interface Props {
  children: React.ReactNode;
}

export const AudioPlayerProvider = ({ children }: Props) => {
  const { windowStack, showWindow, hideWindow } = useWindowContext();
  const { service, isSpotifyAuthorized, isAppleAuthorized, toggleTutorial } =
    useSettings();
  const { spotifyPlayer, accessToken, deviceId } = useSpotifySDK();
  const { music } = useMusicKit();
  const [volume, setVolume] = useState(1.0);
  const [nowPlayingItem, setNowPlayingItem] = useState<IpodApi.MediaItem>();
  const [playbackInfo, setPlaybackInfo] = useState(defaultPlatbackInfoState);
  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  const [shouldAutoPlay, setShouldAutoPlay] = useState(false);

  const [songLoadStartTime, setSongLoadStartTime] = useState(0);
  const [regenSongLoadStartTime, setRegenSongLoadStartTime] = useState(-1);
  const [firstSongLoadStartTime, setFirstSongLoadStartTime] = useState(0);
  const [shouldTriggerPlayOnReady, setShouldTriggerPlayOnReady] =
    useState(false);

  useEffect(() => {
    if (!!audioPlayerRef) {
      const d = Date.now();
      setFirstSongLoadStartTime(d);
    }
  }, [audioPlayerRef]);

  const setIsLoading = useCallback((isLoadingArg: boolean) => {
    setPlaybackInfo((prevState) => ({
      ...prevState,
      isLoading: isLoadingArg,
    }));
  }, []);

  const playPlxFirebase = useCallback(
    async (queueOptions: IpodApi.QueueOptions) => {
      setPlaybackInfo((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      updateNowPlayingItem(queueOptions.song);
      const d = Date.now();
      setSongLoadStartTime(d);
      setFirstSongLoadStartTime(-1);

      await playSong();
    },
    [playbackInfo, nowPlayingItem]
  );

  const playSong = async () => {
    try {
      await audioPlayerRef?.current?.play();
    } catch (e: any) {
      const stringifiedError = e.toString();
      logEvent(analytics, "Error.Player.Play", {
        error_message: stringifiedError,
      });
      alert("Something went wrong, please refresh your browser. Sorry!");
    }
  };

  const setInitialSongPlaceholder = useCallback(
    async (initialSong: IpodApi.Song) => {
      setNowPlayingItem(initialSong);
    },
    [setNowPlayingItem]
  );

  const play = useCallback(
    async (queueOptions: IpodApi.QueueOptions) => {
      switch (service) {
        // case "apple":
        //   return playAppleMusic(queueOptions);
        // case "spotify":
        //   return playSpotify(queueOptions);
        case "plxFirebase":
          return playPlxFirebase(queueOptions);
        default:
          throw new Error("Unable to play: service not specified");
      }
    },
    [service]
  );

  const pause = useCallback(async () => {
    switch (service) {
      // case "apple":
      //   return spotifyPlayer.pause();
      // case "spotify":
      //   return music.pause();
      case "plxFirebase":
        audioPlayerRef?.current?.pause();
        return;
      default:
        throw new Error("Unable to play: service not specified");
    }
  }, [music, service, spotifyPlayer]);

  const togglePlayPause = useCallback(async () => {
    const activeWindow = windowStack[windowStack.length - 1];

    // Don't toggle play/pause when using the on-screen keyboard.
    if (!nowPlayingItem || activeWindow.id === ViewOptions.keyboard.id) {
      return;
    }

    if (audioPlayerRef?.current?.paused) {
      if (
        activeWindow.id !== ViewOptions.nowPlaying.id &&
        activeWindow.id !== ViewOptions.regenerateSongPopup.id
      ) {
        showWindow({
          id: ViewOptions.nowPlaying.id,
          type: WINDOW_TYPE.FULL,
          component: NowPlayingView,
        });
      }

      await playSong();
    } else {
      audioPlayerRef?.current?.pause();
    }
  }, [audioPlayerRef, nowPlayingItem, windowStack]);

  const regenerateSong = useCallback(async () => {
    if (!nowPlayingItem) {
      // TODO: set a new item
    }

    // TODO: check if need to deep copy
    let newSongVersion = nowPlayingItem as IpodApi.MediaItem;

    if (newSongVersion!.versionsNotPlayed?.length === 0) {
      const versionsNotPlayed = createNotPlayedVersionsArray(
        newSongVersion.totalVersions!.toString(),
        newSongVersion.currentVersionNumber!.toString()
      );
      newSongVersion.versionsNotPlayed = versionsNotPlayed;
    }

    let genUrls = getGenerativeUrls(
      {
        artworkUri: nowPlayingItem!.artworkUris!,
        songUri: nowPlayingItem!.songUris!,
        numberOfVersions: parseInt(nowPlayingItem!.totalVersions!),
      },
      newSongVersion.versionsNotPlayed!
    );

    newSongVersion!.artwork = {
      url: genUrls.artworkUrl,
    };
    newSongVersion!.url = genUrls.songUrl;
    newSongVersion!.currentVersionNumber = genUrls.currentVersionNumber;
    newSongVersion.versionsNotPlayed = genUrls.versionsNotPlayed;

    const d = Date.now();
    setIsLoading(true);
    setShouldTriggerPlayOnReady(true);
    setShouldAutoPlay(true);
    setRegenSongLoadStartTime(d);
    setFirstSongLoadStartTime(-1);
    setNowPlayingItem(newSongVersion);
  }, [nowPlayingItem]);

  const updateNowPlayingItem = useCallback(
    async (song?: IpodApi.Song) => {
      if (!song) return;

      let mediaItem: IpodApi.MediaItem | undefined;

      // TODO: Update types for MusicKit V3
      // if (service === "apple" && (music as any).nowPlayingItem) {
      //   // TODO: Update types for MusicKit V3
      //   mediaItem = ConversionUtils.convertAppleMediaItem(
      //     (music as any).nowPlayingItem
      //   );
      // } else if (service === "spotify") {
      //   const state = await spotifyPlayer.getCurrentState();

      //   if (state) {
      //     mediaItem = ConversionUtils.convertSpotifyMediaItem(state);
      //   }
      // } else
      if (service === "plxFirebase") {
        mediaItem = song;
      }

      setNowPlayingItem(mediaItem);
    },
    [music, service, spotifyPlayer]
  );

  const handlePlxPlaybackStateChange = useCallback(() => {
    // let isLoading = false;
    let isPlaying = false;
    let isPaused = false;

    if (audioPlayerRef?.current?.paused) {
      isPaused = true;
    } else if (audioPlayerRef?.current && !audioPlayerRef.current.paused) {
      isPlaying = true;
    }

    setPlaybackInfo((prevState) => ({
      ...prevState,
      isPlaying,
      isPaused,
      // isLoading,
    }));

    // updateNowPlayingItem();
    // }, [updateNowPlayingItem]);
  }, []);

  const resetPlaybackInfo = useCallback(async () => {
    if (service === "plxFirebase") {
      const currentTime = 0;
      const duration = 0;
      const timeRemaining = duration - currentTime;
      const percent = 0;

      setPlaybackInfo((prevState) => ({
        ...prevState,
        currentTime,
        timeRemaining,
        percent,
        duration,
      }));
    }
  }, [music, service, spotifyPlayer]);

  const updatePlaybackInfo = useCallback(async () => {
    if (service === "apple") {
      setPlaybackInfo((prevState) => ({
        ...prevState,
        // TODO: Update types for MusicKit V3
        currentTime: (music as any).currentPlaybackTime,
        timeRemaining: (music as any).currentPlaybackTimeRemaining,
        percent: (music as any).currentPlaybackProgress * 100,
        duration: (music as any).currentPlaybackDuration,
      }));
    } else if (service === "spotify") {
      const { position, duration } =
        (await spotifyPlayer.getCurrentState()) ?? {};
      const currentTime = (position ?? 0) / 1000;
      const maxTime = (duration ?? 0) / 1000;
      const timeRemaining = maxTime - currentTime;
      const percent = Math.round((currentTime / maxTime) * 100);

      setPlaybackInfo((prevState) => ({
        ...prevState,
        currentTime,
        timeRemaining,
        percent,
        duration: maxTime,
      }));
    } else if (service === "plxFirebase") {
      const currentTime = audioPlayerRef?.current?.currentTime ?? 0;
      // const duration = audioPlayerRef?.current?.duration
      //   ? audioPlayerRef?.current?.duration
      //   : nowPlayingItem?.duration!;
      const duration = audioPlayerRef?.current?.duration ?? 0;
      const timeRemaining = duration - currentTime;
      const percent = Math.round((currentTime / duration) * 100);

      // console.log("TIME REMAINIGN: ", {
      //   timeRemaining,
      //   duration,
      //   stuff: audioPlayerRef?.current,
      // });

      setPlaybackInfo((prevState) => ({
        ...prevState,
        currentTime,
        timeRemaining,
        percent,
        duration,
      }));
    }
  }, [music, service, spotifyPlayer]);

  const seekToTime = useCallback(
    async (time: number) => {
      if (service === "apple") {
        // TODO: Update types for MusicKit V3
        await (music as any).player.seekToTime(time);
      } else if (service === "spotify") {
        // Seek to time (in ms)
        await spotifyPlayer.seek(time * 1000);
      } else if (service === "plxFirebase") {
        if (audioPlayerRef?.current) {
          audioPlayerRef.current.currentTime = time;
        }
      }

      updatePlaybackInfo();
    },
    [music, service, spotifyPlayer, updatePlaybackInfo]
  );

  const handleChangeVolume = useCallback(
    (newVolume: number) => {
      if (audioPlayerRef?.current) {
        audioPlayerRef.current.volume = newVolume;
      }

      // if (isSpotifyAuthorized) {
      //   spotifyPlayer.setVolume(newVolume);
      // }

      // if (isAppleAuthorized) {
      //   // TODO: Update types for MusicKit V3
      //   (music as any).volume = newVolume;
      // }

      localStorage.setItem(VOLUME_KEY, `${newVolume}`);

      setVolume(newVolume);
    },
    [isAppleAuthorized, isSpotifyAuthorized, music, spotifyPlayer]
  );

  useEventListener<IpodEvent>("playpauseclick", () => {
    togglePlayPause();
    handlePlxPlaybackStateChange();
  });

  useEventListener<IpodEvent>("forwardclick", () => {
    const activeWindow = windowStack[windowStack.length - 1];
    if (
      activeWindow.id !== ViewOptions.nowPlaying.id &&
      activeWindow.id !== ViewOptions.regenerateSongPopup.id
    ) {
      showWindow({
        id: ViewOptions.nowPlaying.id,
        type: WINDOW_TYPE.FULL,
        component: NowPlayingView,
      });
    }

    let songTitle = nowPlayingItem?.name ?? "song";
    if (activeWindow.id !== ViewOptions.regenerateSongPopup.id) {
      showWindow({
        type: WINDOW_TYPE.POPUP,
        id: ViewOptions.regenerateSongPopup.id,
        title: ViewOptions.regenerateSongPopup.title,
        // description: `Generate new version of ${songTitle}?`,
        description: `Listen to different version of ${songTitle}?`,
        // description: `Listen to new version of ${songTitle}?`,
        listOptions: [
          {
            type: "Action",
            label: "Yes",
            onSelect: () => {
              // hideWindow();
              logEvent(analytics, "UI.RegenPopup.Yes");
              regenerateSong();
              handlePlxPlaybackStateChange();
            },
          },
          {
            type: "Action",
            label: "No",
            onSelect: () => {
              logEvent(analytics, "UI.RegenPopup.No");
            },
          },
        ],
      });
    }
    // skipNext();
  });

  useEventListener<any>(
    "canplaythrough",
    () => {
      setIsLoading(false);
      const endLoadTime = Date.now();
      let totalLoadTime = 0;
      if (songLoadStartTime === 0) {
        totalLoadTime = endLoadTime - firstSongLoadStartTime;
        logEvent(analytics, "Perf.SongLoad.First", {
          load_time: totalLoadTime,
        });
        setSongLoadStartTime(-1);
      } else if (regenSongLoadStartTime >= 0) {
        totalLoadTime = endLoadTime - regenSongLoadStartTime;
        logEvent(analytics, "Perf.SongLoad.Regen", {
          load_time: totalLoadTime,
        });
        setRegenSongLoadStartTime(-1);
      } else if (firstSongLoadStartTime !== -2) {
        // small hack to fix perf logging bug
        totalLoadTime = endLoadTime - songLoadStartTime;
        logEvent(analytics, "Perf.SongLoad.Normal", {
          load_time: totalLoadTime,
        });
      }

      if (shouldTriggerPlayOnReady) {
        playSong();
        setShouldTriggerPlayOnReady(false);
      }
    },
    audioPlayerRef?.current
  );

  useEventListener<any>(
    "error",
    (e) => {
      if (!e.target) {
        return;
      }

      let errorMessage = e.target.error.message;
      let errorName = "UNKNOWN_ERROR";

      let alertMessagePrefix =
        "Please refresh your browser or try a different one...";
      let alertMessage = "An error occurred, sorry!";

      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
          alertMessage = "You aborted the video playback. Sorry!";
          errorName = "MEDIA_ERR_ABORTED";
          break;
        case e.target.error.MEDIA_ERR_NETWORK:
          alertMessage =
            "A network error caused the audio download to fail. Sorry!";
          errorName = "MEDIA_ERR_NETWORK";
          break;
        case e.target.error.MEDIA_ERR_DECODE:
          alertMessage =
            "The audio playback was aborted due to a corruption problem or because the video used features your browser did not support. Sorry!";
          errorName = "MEDIA_ERR_DECODE";
          break;
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          alertMessage =
            "The audio could not be loaded, either because the server or network failed or because the format is not supported. Sorry!";
          errorName = "MEDIA_ERR_SRC_NOT_SUPPORTED";
          break;
        default:
          break;
      }

      logEvent(analytics, "Error.Player.HTMLAudio", {
        error_message: errorMessage,
        error_name: errorName,
      });

      alert(alertMessagePrefix + "\n\n" + alertMessage);
    },
    audioPlayerRef?.current
  );

  useEventListener<IpodEvent>("backwardclick", () => {
    toggleTutorial();
  });

  useEventListener<any>(
    "pause",
    () => {
      handlePlxPlaybackStateChange();
    },
    audioPlayerRef?.current
  );

  useEventListener<any>(
    "play",
    () => {
      handlePlxPlaybackStateChange();
    },
    audioPlayerRef?.current
  );

  useEffect(() => {
    if (isAppleAuthorized) {
      const savedVolume = parseFloat(localStorage.getItem(VOLUME_KEY) ?? "0.5");
      handleChangeVolume(savedVolume);
    }
  }, [handleChangeVolume, isAppleAuthorized]);

  let audioSource = nowPlayingItem?.url;

  return (
    <AudioPlayerContext.Provider
      value={{
        playbackInfo,
        nowPlayingItem,
        volume,
        play,
        pause,
        seekToTime,
        setVolume: handleChangeVolume,
        updateNowPlayingItem,
        updatePlaybackInfo,
        resetPlaybackInfo,
        setInitialSongPlaceholder,
        setShouldAutoPlay,
        setFirstSongLoadStartTime,
      }}
    >
      {!audioPlayerRef ? undefined : (
        <audio
          ref={audioPlayerRef}
          src={audioSource}
          autoPlay={shouldAutoPlay}
          // autoPlay={autoPlay}
          // autoPlay={true}
          controls={false}
          preload={"auto"}
          // muted={true}
          // style={{ visibility: "hidden" }}
        />
      )}
      {children}
    </AudioPlayerContext.Provider>
  );
};

// const PLXPlayer = React.forwardRef((props, ref) => {
//   <ReactAudioPlayer
//     id={"audioPlayer"}
//     src="https://supernova.mypinata.cloud/ipfs/QmR76Gj5Yo3vLnCkJLy5uhwpW8w9qfpzVRKPREkMz7PvSe"
//     autoPlay={true}
//     controls={false}
//     // style={{ visibility: "hidden" }}
//   />;
// });

export default useAudioPlayer;
