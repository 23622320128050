import { useMemo } from "react";

import { SelectableList, SelectableListOption } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import * as Utils from "utils";

interface Props {
  songs: IpodApi.Song[];
}

const GenerativeSongsView = ({ songs }: Props) => {
  useMenuHideWindow(ViewOptions.generativeSongs.id);

  let options: SelectableListOption[] = useMemo(
    () =>
      songs.map((song) => ({
        type: "Song",
        label: song.name,
        // sublabel: `${song.artistName} • ${song.albumName}`,
        sublabel: `${song.artistName} • v${song.currentVersionNumber}`,
        queueOptions: {
          song,
          startPosition: 0,
        },
        imageUrl: song.artwork?.url, //  Utils.getArtwork(50, song.artwork?.url),
        showNowPlayingView: true, // this is what opens the now playing screen
        longPressOptions: Utils.getMediaOptions("song", song.id),
      })) ?? [],
    [JSON.stringify(songs)]
  );

  const { scrollIndex, setActiveIndex } = useScrollHandler(
    ViewOptions.generativeSongs.id,
    options
  );

  return (
    <SelectableList
      options={options}
      activeIndex={scrollIndex}
      emptyMessage="Loading songs... please refresh"
      setActiveIndex={setActiveIndex}
    />
  );
};

export default GenerativeSongsView;
