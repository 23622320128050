import React, { SyntheticEvent } from "react";

import { useSettings } from "hooks";
import styled from "styled-components";

import { DeviceThemeName, getTheme } from "../../utils/themes";
import PlayPauseIcon from "./icons/PlayPauseIcon";
import GenerateSongIcon from "./icons/GenerateSongIcon";
import HelpIcon from "./icons/HelpIcon";
import { Screen } from "utils/constants";
import Tutorial from "./Tutorial";
import HomeIcon from "./icons/HomeIcon";

const ContainerTop = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 0;
  touch-action: none;
  transform: translate3d(0, 0, 0);
  padding-top: 10px;
  z-index: 5;

  // border-width: 7px;
  // border-color: red;
  // border-style: solid;

  ${Screen.SM.MediaQuery} {
    padding-bottom: 35px;
    padding-top: 20px;
  }
`;

const Container = styled.div`
  // user-select: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0;
  // touch-action: none;
  // transform: translate3d(0, 0, 0);
  padding-top: 20px;
  z-index: 5;
  width: 300px;

  // border-width: 7px;
  // border-color: blue;
  // border-style: solid;

  ${Screen.SM.MediaQuery} {
    padding-bottom: 35px;
    padding-top: 20px;
  }
`;

const Canvas = styled.canvas<{ deviceTheme: DeviceThemeName }>`
  border-radius: 50%;
  // border: 3px solid ${({ deviceTheme }) =>
    getTheme(deviceTheme).knob.outline};
  // border: 1px solid #b5b5b5;
  // border: 1px solid #404040;
  border: 1px solid #525252;
  // box-shadow: 0px -5px 80px -10px #353535;
  // box-shadow: 0px -5px 80px -10px #353535;
  // border: 2px solid #bababa;
  background: ${({ deviceTheme }) => getTheme(deviceTheme).knob.background};
`;

const CenterButton = styled.div<{ size: number; deviceTheme: DeviceThemeName }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: ${(props) => props.size / 2.5}px;
  height: ${(props) => props.size / 2.5}px;
  border-radius: 50%;
  box-shadow: ${({ deviceTheme }) =>
    getTheme(deviceTheme).knob.centerButton.boxShadow}
    0px 1em 3em inset;
  background: ${({ deviceTheme }) =>
    getTheme(deviceTheme).knob.centerButton.background};
  border: 1px solid
    ${({ deviceTheme }) => getTheme(deviceTheme).knob.centerButton.outline}};

  :active {
    filter: brightness(0.9);
  }
`;

/** Custom Event from https://github.com/john-doherty/long-press-event  */
interface LongPressEvent extends SyntheticEvent {
  detail: {
    clientX: number;
    clientY: number;
  };
}

const ANGLE_ARC = (360 * Math.PI) / 180;
const ANGLE_OFFSET = (0 * Math.PI) / 180;
const START_ANGLE = 1.5 * Math.PI + ANGLE_OFFSET;
const END_ANGLE = 1.5 * Math.PI + ANGLE_OFFSET + ANGLE_ARC;

type Props = {
  onWheelClick?: (value: number) => void;
  className?: string;
};

const ButtonsBarContainer = ({ onWheelClick = () => {}, className }: Props) => {
  const { deviceTheme } = useSettings();

  const buttonColor = getTheme(deviceTheme).knob.button; // "#800020"; //"black"; //

  return (
    <>
      <ContainerTop>
        <Container className={className}>
          <HelpIcon
            top={"0"}
            color={buttonColor}
            onClick={() => onWheelClick(3)}
          />
          <HomeIcon
            top={"0"}
            color={buttonColor}
            onClick={() => onWheelClick(1)}
          />
          <PlayPauseIcon
            top={"0"}
            color={buttonColor}
            onClick={() => onWheelClick(2)}
          />
          <GenerateSongIcon
            top={"0"}
            color={buttonColor}
            onClick={() => onWheelClick(4)}
          />
        </Container>
      </ContainerTop>
      <Tutorial />
    </>
  );
};

export default ButtonsBarContainer;
